/* eslint-disable */

<template>
  <div>
    <vs-table :data="drives" class="mb-3">
      <template slot="thead">
        <vs-th>Volume</vs-th>
        <vs-th>Layout</vs-th>
        <vs-th>File System</vs-th>
        <vs-th>Status</vs-th>
        <vs-th>Capacity</vs-th>
        <vs-th>Free Space</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].volume">
            {{ data[indextr].volume }}
          </vs-td>

          <vs-td :data="data[indextr].layout">
            {{ data[indextr].layout }}
          </vs-td>

          <vs-td :data="data[indextr].file_system">
            {{ data[indextr].file_system }}
          </vs-td>

          <vs-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </vs-td>

          <vs-td :data="data[indextr].capacity">
            {{ data[indextr].capacity }}
          </vs-td>

          <vs-td :data="data[indextr].free_space">
            {{ data[indextr].free_space }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-card p-5 mb-3">
      <div class="vx-row">
        <div class="vx-col w-1/5 flex flex-col justify-center">
          <div class="">
            <h4 class="font-bold">{{ disks['0'].name }}</h4>
            <h4>{{ disks['0'].type }}</h4>
            <h4>{{ disks['0'].space }}</h4>
            <h4>{{ disks['0'].status }}</h4>
          </div>
        </div>
        <div class="vx-col w-4/5">
          <div class="w-full border-solid border">
            <div class="mb-2" style="background: #0015ff; height: 20px"></div>
            <div class="p-2 pt-0">
              <p>(C:)</p>
              <p>250GB NFTS</p>
              <p>Healthy (Boot, Page File, Crash Dump, Primary Partition)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-card p-5 mb-3" @contextmenu.prevent="$refs.disk1menu.open">
      <div class="vx-row">
        <div class="vx-col w-1/5 flex flex-col justify-center">
          <div class="">
            <h4 class="font-bold">{{ disks['1'].name }}</h4>
            <h4>{{ disks['1'].type }}</h4>
            <h4>{{ disks['1'].space }}</h4>
            <h4>{{ disks['1'].status }}</h4>
          </div>
        </div>
        <div class="vx-col w-4/5">
          <div class="w-full border-solid border" v-if="!steps.volume.F && !steps.volume.G">
            <div class="mb-2" style="background: #000000; height: 20px"></div>
            <div class="p-2 pt-0">
              <p>750GB</p>
              <p>UNALLOCATED</p>
            </div>
          </div>
          <div class="flex" v-else>
            <div class="w-1/3 border-solid border">
              <div class="mb-2" :style="`background: ${steps.volume.F ? '#969100' : '#000000'};`" style="height: 20px"></div>
              <div class="p-2 pt-0">
                <p>{{ steps.volume.F ? 'New Volume (F:)' : '' }}</p>
                <p>{{ steps.volume.F ? '250GB NTFS' : '250GB' }}</p>
                <p>{{ steps.volume.F ? 'Healthy' : 'UNALLOCATED' }}</p>
              </div>
            </div>
            <div class="w-2/3 border-solid border">
              <div class="mb-2" :style="`background: ${steps.volume.G ? '#969100' : '#000000'};`" style="height: 20px"></div>
              <div class="p-2 pt-0">
                <p>{{ steps.volume.G ? 'New Volume (G:)' : '' }}</p>
                <p>{{ steps.volume.G ? '500GB NTFS' : '500GB' }}</p>
                <p>{{ steps.volume.G ? 'Healthy' : 'UNALLOCATED' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-context ref="disk1menu">
      <li>
        <a href="#" @click="initializeDiskPopup()" class="flex items-center text-sm">
          <feather-icon icon="ToolIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">Initialize</span>
        </a>
      </li>
      <li>
        <a href="#" @click="volumeCreatePopup" class="flex items-center text-sm">
          <feather-icon icon="PlusIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">New Simple Volume</span>
        </a>
      </li>
      <li>
        <a href="#" class="flex items-center text-sm">
          <feather-icon icon="PlusIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">New Spanned Volume</span>
        </a>
      </li>
      <li>
        <a href="#" class="flex items-center text-sm">
          <feather-icon icon="PlusIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">New Striped Volume</span>
        </a>
      </li>
      <li>
        <a href="#" class="flex items-center text-sm">
          <feather-icon icon="PlusIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">New Mirrored Volume</span>
        </a>
      </li>
      <li>
        <a href="#" class="flex items-center text-sm">
          <feather-icon icon="PlusIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">New RAID-5 Volume</span>
        </a>
      </li>
      <li>
        <a href="#" @click="dynamicDiskPopup()" class="flex items-center text-sm">
          <feather-icon icon="HardDriveIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">Convert To Dynamic Disk</span>
        </a>
      </li>
      <li>
        <a href="#" class="flex items-center text-sm">
          <feather-icon icon="CloudOffIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">Offline</span>
        </a>
      </li>
      <li>
        <a href="#" class="flex items-center text-sm">
          <feather-icon icon="InfoIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">Properties</span>
        </a>
      </li>
    </vue-context>
    <div class="vx-card p-5 mb-3">
      <div class="vx-row">
        <div class="vx-col w-1/5 flex flex-col justify-center">
          <div class="">
            <h4 class="font-bold">{{ disks['2'].name }}</h4>
            <h4>{{ disks['2'].type }}</h4>
            <h4>{{ disks['2'].space }}</h4>
            <h4>{{ disks['2'].status }}</h4>
          </div>
        </div>
        <div class="vx-col w-4/5">
          <div class="w-full border-solid border">
            <div class="mb-2" style="background: #000000; height: 20px"></div>
            <div class="p-2 pt-0">
              <p>750GB</p>
              <p>UNALLOCATED</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vs-popup :active.sync="popups.error.active" class="pb-3" :title="popups.error.title">
      <div class="flex justify-center mt-3 mb-5">
        <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/success.png" width="150" alt="Check" />
      </div>
      <h1 class="text-center font-bold my-6" style="font-size: 42px" v-html="popups.error.title" />
      <h3 class="text-center font-light mb-3" v-html="popups.error.desc" />
      <div class="flex justify-center mt-3">
        <vs-button class="mt-5 mb-5" color="warning" @click="popups.error.active = false">Try Again </vs-button>
      </div>
    </vs-popup>
    <movable-popup
      refVal="initializeDiskPopupRef"
      title="Initialize Disk - Click Here to Drag This Popup"
      :active="popups.initialize.active"
      v-on:close="popups.initialize.active = false"
      width="35vw"
    >
      <template v-slot:content>
        <div class="font-bold mb-4"><h4>You must initialize a disk before disk manager is able to access it.</h4></div>
        <div class="flex items-center mb-4">
          <feather-icon icon="HardDriveIcon" svgClasses="w-8 h-8 mr-4"></feather-icon>
          <vs-switch v-model="popups.initialize.disks['1'].val" class="mr-4" />
          <h5 class="font-light">Disk 1</h5>
        </div>
        <div class="flex items-center mb-4">
          <feather-icon icon="HardDriveIcon" svgClasses="w-8 h-8 mr-4"></feather-icon>
          <vs-switch v-model="popups.initialize.disks['2'].val" class="mr-4" />
          <h5 class="font-light">Disk 2</h5>
        </div>
        <div class="mb-4">
          <div class="flex items-center mb-2">
            <feather-icon icon="ToolIcon" svgClasses="w-8 h-8 mr-4"></feather-icon>
            <h5 class="font-light">Partition Style</h5>
          </div>
          <v-select v-model="popups.initialize.partition.val" :options="popups.initialize.partition.options" class="w-full" />
        </div>
        <div class="font-bold mb-4"><h5>Ensure information is correct before proceeding.</h5></div>
        <vs-divider />
        <div class="flex items-end justify-end">
          <vs-button color="success" class="ml-2" @click="initializeDiskComplete">Initialize Disk</vs-button>
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="volumeCreatePopupRef"
      title="Create Simple Volume - Click Here to Drag This Popup"
      :active="popups.volume.active"
      v-on:close="popups.volume.active = false"
      width="30vw"
    >
      <template v-slot:content>
        <div class="flex items-center mb-4">
          <feather-icon icon="Maximize2Icon" svgClasses="w-8 h-8 mr-4"></feather-icon>
          <h5 class="font-bold mr-2">Max Disk Space [MB]</h5>
          <h5 class="font-light">76800</h5>
        </div>
        <div class="flex items-center mb-4">
          <feather-icon icon="Minimize2Icon" svgClasses="w-8 h-8 mr-4"></feather-icon>
          <h5 class="font-bold mr-2">Min Disk Space [MB]</h5>
          <h5 class="font-light">8</h5>
        </div>
        <div class="mb-4">
          <div class="flex items-center mb-2">
            <feather-icon icon="HardDriveIcon" svgClasses="w-8 h-8 mr-4"></feather-icon>
            <h5 class="font-bold">Simple Volume Size [MB]</h5>
          </div>
          <vs-input v-model="popups.volume.sizeVal" class="w-full"></vs-input>
        </div>
        <div class="mb-4">
          <div class="flex items-center mb-4">
            <vs-switch class="mr-4" v-model="popups.volume.assignLetter" />
            <h5 class="font-bold">Assign Letter To Drive</h5>
          </div>
          <v-select
            v-if="popups.volume.assignLetter"
            v-model="popups.volume.selectedLetter"
            :options="popups.volume.letters"
            class="w-full"
          />
        </div>
        <div class="mb-4">
          <div class="flex items-center mb-4">
            <vs-switch class="mr-4" v-model="popups.volume.formatDrive" />
            <h5 class="font-bold">Format Drive</h5>
          </div>
          <v-select
            v-if="popups.volume.formatDrive"
            v-model="popups.volume.selectedFormat"
            :options="popups.volume.formats"
            placeholder="Select Format Type..."
            class="w-full"
          />
        </div>
        <vs-divider />
        <div class="flex items-end justify-end">
          <vs-button color="success" class="ml-2" icon="icon-arrow-right" icon-pack="feather" icon-after @click="volumeCreateComplete"
            >Finish
          </vs-button>
        </div>
      </template>
    </movable-popup>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import { VueContext } from 'vue-context';
import MovablePopup from '../../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      drives: [
        {
          volume: '(C:)',
          layout: 'Simple',
          file_system: 'NTFS',
          status: 'Healthy',
          capacity: '250GB',
          free_space: '240GB',
        },
      ],

      disks: {
        0: {
          name: 'DISK 0',
          type: 'Basic',
          space: '250GB',
          status: 'Online',
        },
        1: {
          name: 'DISK 1',
          type: 'Basic',
          space: '750GB',
          status: 'Not Installed',
        },
        2: {
          name: 'DISK 2',
          type: 'Basic',
          space: '750GB',
          status: 'Not Installed',
        },
      },

      currentStep: 0,
      volumeEditing: 'F',

      steps: {
        initialize: false,
        dynamic: false,
        volume: {
          F: false,
          G: false,
        },
      },

      popups: {
        error: {
          active: false,
          title: '',
          desc: '',
        },
        initialize: {
          active: false,
          disks: {
            1: {
              val: false,
              answer: true,
            },
            2: {
              val: false,
              answer: false,
            },
          },
          partition: {
            val: null,
            answer: 'MBR (Master Boot Record)',
            options: ['MBR (Master Boot Record)', 'GPT (GUID Partition Table)'],
          },
        },
        volume: {
          active: false,
          sizeVal: null,
          assignLetter: false,
          selectedLetter: 'A',
          letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
          formatDrive: false,
          selectedFormat: null,
          formats: ['NTFS', 'exFAT', 'FAT32', 'HFS'],
        },
      },
    };
  },
  methods: {
    initializeDiskPopup() {
      if (this.currentStep > 0 || this.steps.initialize) {
        this.popups.error = {
          active: true,
          title: 'Step Already Completed',
          desc: 'Move onto the next step to continue.',
        };
        return;
      }
      this.popups.initialize.active = true;
    },
    initializeDiskComplete() {
      if (
        this.popups.initialize.disks['1'].val !== this.popups.initialize.disks['1'].answer ||
        this.popups.initialize.disks['2'].val !== this.popups.initialize.disks['2'].answer ||
        this.popups.initialize.partition.val !== this.popups.initialize.partition.answer
      ) {
        this.popups.initialize.active = false;
        this.popups.error = {
          active: true,
          title: 'Incorrect Settings',
          desc: 'The disk has not been initialized correctly.',
        };
        return;
      }
      this.$vs.notify({
        title: 'Disk Initialized',
        text: 'Disk 1 has been initialized successfully',
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-check-circle',
      });
      this.disks['1'].status = 'Online';
      this.currentStep = 1;
      this.steps.initialize = true;
      this.popups.initialize.active = false;
    },
    dynamicDiskPopup() {
      if (this.currentStep > 1 || this.steps.dynamic) {
        this.popups.error = {
          active: true,
          title: 'Step Already Completed',
          desc: 'Move onto the next step to continue.',
        };
        return;
      }
      if (this.currentStep < 1) {
        this.popups.error = {
          active: true,
          title: 'Previous Step Required',
          desc: 'You must complete the previous step first.',
        };
        return;
      }
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: 'Convert To Dynamic Disk',
        text: 'Please confirm you want to convert this to a dynamic disk.',
        accept: this.dynamicDiskComplete,
        acceptText: 'Convert',
      });
    },
    dynamicDiskComplete() {
      this.$vs.notify({
        title: 'Disk Converted',
        text: 'Disk 1 has been converted to a dynamic disk successfully.',
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-check-circle',
      });
      this.disks['1'].type = 'Dynamic';
      this.currentStep = 2;
      this.steps.dynamic = true;
    },
    volumeCreatePopup() {
      const stepsDone = this.steps.volume.F && this.steps.volume.G;
      if (this.currentStep > 2 || stepsDone) {
        this.popups.error = {
          active: true,
          title: 'Step Already Completed',
          desc: 'Move onto the next step to continue.',
        };
        return;
      }
      if (this.currentStep < 2) {
        this.popups.error = {
          active: true,
          title: 'Previous Step Required',
          desc: 'You must complete the previous step first.',
        };
        return;
      }
      this.popups.volume.active = true;
    },
    volumeCreateComplete() {
      let success = false;
      if (
        this.popups.volume.sizeVal === '256000' &&
        this.popups.volume.selectedLetter === 'F' &&
        this.popups.volume.selectedFormat === 'NTFS'
      ) {
        success = true;
        this.$vs.notify({
          title: 'Volume Created',
          text: 'New volume (F:) has been created successfully',
          color: 'success',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-check-circle',
        });
        this.steps.volume.F = true;
      }
      if (
        this.popups.volume.sizeVal === '512000' &&
        this.popups.volume.selectedLetter === 'G' &&
        this.popups.volume.selectedFormat === 'NTFS'
      ) {
        success = true;
        this.$vs.notify({
          title: 'Volume Created',
          text: 'New volume (G:) has been created successfully',
          color: 'success',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-check-circle',
        });
        this.steps.volume.G = true;
      }
      if (success === false) {
        this.volumeCreateReset();
        this.popups.error = {
          active: true,
          title: 'No Volume Created',
          desc: 'Ensure the values inputted match the ones in the instructions.',
        };
        return;
      }

      this.drives = [{ volume: '(C:)', layout: 'Simple', file_system: 'NTFS', status: 'Healthy', capacity: '250GB', free_space: '240GB' }];
      if (this.steps.volume.F) {
        this.drives.push({
          volume: '(F:)',
          layout: 'Dynamic',
          file_system: 'NTFS',
          status: 'Healthy',
          capacity: '250GB',
          free_space: '247GB',
        });
      }
      if (this.steps.volume.G) {
        this.drives.push({
          volume: '(G:)',
          layout: 'Dynamic',
          file_system: 'NTFS',
          status: 'Healthy',
          capacity: '500GB',
          free_space: '497GB',
        });
      }

      if (this.steps.volume.F && this.steps.volume.G) {
        this.currentStep = 3;
        this.markSimulation();
      }

      this.volumeCreateReset();
    },
    volumeCreateReset() {
      this.popups.volume.active = false;
      this.popups.volume.sizeVal = null;
      this.popups.volume.selectedLetter = null;
      this.popups.volume.selectedFormat = null;
      this.popups.volume.assignLetter = false;
      this.popups.volume.formatDrive = false;
    },
    markSimulation() {
      return this.$emit('simulation_full_correct', 1);
    },
  },
  mounted() {},
  components: {
    Prism,
    vSelect,
    VueContext,
    MovablePopup,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
